import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import logo from './img/logo.png'

function BasicExample() {
    const [expanded, setExpanded] = useState(false);

    const handleNavItemClick = () => {
        setExpanded(false);
    };

    return (
        <Navbar expand="lg" expanded={expanded} className="navigation">
            <Container>
                <Navbar.Brand as={Link} to="/" onClick={handleNavItemClick}>
                    <img src={logo} />
                </Navbar.Brand>
                <Navbar.Toggle
                    aria-controls="navbarSupportedContent"
                    onClick={() => setExpanded(!expanded)}
                />
                <Navbar.Collapse id="navbarSupportedContent">

                    <Nav className="me-auto">
                        <Nav.Link as={Link} to="/" className="color" onClick={handleNavItemClick}>Home</Nav.Link>

                        <Nav.Link as={Link} to="/About" className="color" onClick={handleNavItemClick}>About</Nav.Link>

                        <Nav.Link as={Link} to="/Event" className="color" onClick={handleNavItemClick}>Our Events</Nav.Link>


                        <NavDropdown title="Services" id="basic-nav-dropdown" className="custom-dropdown-title">
                            <NavDropdown.Item as={Link} to="/PashudhanVikas" onClick={handleNavItemClick}>पशुधन विकास परिषद</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/VectorControl" onClick={handleNavItemClick}>वेक्टर जनित नियंत्रण कार्यक्रम</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/TBControl" onClick={handleNavItemClick}>छय रोग नियंत्रण कार्यक्रम</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/AnimalDiseaseControl" onClick={handleNavItemClick}>राष्ट्रीय पशु रोग नियंत्रण कार्यक्रम</NavDropdown.Item>
                        </NavDropdown>



                        <Nav.Link as={Link} to="/Gallery" className="color" onClick={handleNavItemClick}>Gallery</Nav.Link>

                        <Nav.Link as={Link} to="/Contact" className="color" onClick={handleNavItemClick}>Contact</Nav.Link>

                        <Nav.Link as={Link} to="/Mahila" className="color" onClick={handleNavItemClick}>महिला  शक्ति </Nav.Link>

                        <Nav.Link as={Link} to="/Kausal" className="color" onClick={handleNavItemClick}>कौशल  विकास  </Nav.Link>

                        <Nav.Link as={Link} to="/News" className="color" onClick={handleNavItemClick}>समाचार पत्र  </Nav.Link>

                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default BasicExample;
