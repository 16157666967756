import React from 'react'
import Footer from '../Components/Footer'
import about from '../Components/Gallery/gal  (6).jpeg'

const About = () => {
    return (
        <>



            <div className='Heading'>
                Home / About
            </div>




            <div className='sections'>

                <div className='section2'>
                    <img src={about} />


                    <br />  <br />
                    {/* <a href="Tel:+91-9415495322">
                        <button class="contact-button">Contact Us | हमारा संपर्क</button>
                    </a> */}

                    <h2>जीवन पथ फाउंडेशन की विशेषताएँ:</h2>
                    <ul>
                        <li><b>सामाजिक बदलाव की दिशा में प्रतिबद्धता:</b> संगठन समाज के कमजोर वर्ग के लिए काम करता है और उन्हें सशक्त बनाने के लिए निरंतर प्रयासरत है।</li>
                        <li><b>स्वयंसेवकों की सक्रिय भागीदारी:</b> जीवन पथ फाउंडेशन में कई स्वयंसेवक काम करते हैं, जो समाज में बदलाव लाने के लिए विभिन्न पहलुओं में योगदान करते हैं।</li>
                        <li><b>विश्वसनीयता और पारदर्शिता:</b> यह संगठन अपनी गतिविधियों में पूरी पारदर्शिता बनाए रखता है, जिससे लोग विश्वास के साथ इसमें योगदान कर सकते हैं।</li>
                    </ul>

                    <p>आप अगर इस संगठन से जुड़ने का विचार कर रहे हैं, तो यह एक अच्छा कदम हो सकता है, क्योंकि यह संगठन न केवल स्थानीय समुदायों को लाभ पहुंचाता है बल्कि वैश्विक स्तर पर भी सकारात्मक प्रभाव डालता है।</p>




                    <h2>पशुधन विकास और स्वास्थ्य कार्यक्रम:</h2>
                    <ul>
                        <li>पशुधन विकास परिषद द्वारा किसानों और पशुपालकों को सहायता प्रदान करना।</li>
                        <li>वेक्टर जनित नियंत्रण कार्यक्रम के माध्यम से मच्छर जनित रोगों का नियंत्रण करना।</li>
                        <li>छय रोग नियंत्रण कार्यक्रम के तहत तपेदिक और अन्य रोगों का इलाज और जागरूकता फैलाना।</li>
                        <li>राष्ट्रीय पशु रोग नियंत्रण कार्यक्रम द्वारा पशुओं में होने वाली गंभीर बीमारियों को नियंत्रित करना।</li>
                        <li>डेयरी संचालन के माध्यम से दुग्ध उत्पादन में सुधार और किसानों को प्रशिक्षण प्रदान करना।</li>
                    </ul>



                </div>


                <div className='section2'>
                    <h1>जीवन पथ फाउंडेशन मे आपका  स्वागत  है</h1>
                    <p>"जीवन पथ फाउंडेशन" एक गैर-लाभकारी संगठन   है, जिसका उद्देश्य समाज में सकारात्मक बदलाव लाना है। यह संगठन विभिन्न समाजिक कार्यों, शिक्षा, स्वास्थ्य, और मानवाधिकार के क्षेत्र में कार्यरत है। जीवन पथ फाउंडेशन का प्रमुख उद्देश्य उन लोगों की मदद करना है जो समाज के हाशिये पर हैं, जैसे गरीब, असहाय, और विकलांग व्यक्ति। इसके अतिरिक्त, यह संगठन विभिन्न शैक्षिक कार्यक्रम, प्रशिक्षण, और जागरूकता अभियानों के माध्यम से लोगों को उनके अधिकारों के बारे में जानकारी देता है।</p>
                    <p> उत्तर प्रदेश के  सभी  75 जनपदों  में   ग्रामीण, खण्ड, और जनपद    स्तरीय कार्यक्रम का क्रियान्वयन   18 मण्डल 834 खण्ड 1,19,000 गांवों में <span style={{ color: '#ff9933', fontWeight: 'bold' }}>जीवन पथ फाउंडेशन के माध्यम से</span>  संचालित किया जा रहा है. </p>



                    <p>भारत सरकार ने 1962 से राष्ट्रीय क्षय नियंत्रण कार्यक्रम लागू किया। इसके अंतर्गत जिला स्तर पर एक निगरानी और पर्यवेक्षण इकाई के रूप में जिला क्षय निवारण केंद्र की स्थापना की गई। हमारे प्रदेश में 1966 से उक्त कार्यक्रम की क्रियान्विति की गई।"  जिनमें केंद्र और राज्य सरकारें मिलकर टीबी रोग के  प्रचार प्रसार को रोकने और इसे पूरी तरह समाप्त करने का लक्ष्य रखती हैं। </p>


                    <h1>जीवन पथ फाउंडेशन के कुछ मुख्य कार्य:</h1>

                    <p><b>शिक्षा:</b></p>
                    <ul>
                        <li>गरीब बच्चों के लिए मुफ्त शिक्षा।</li>
                        <li>शिक्षा के क्षेत्र में जागरूकता फैलाना।</li>
                        <li>महिला शिक्षा को बढ़ावा देना।</li>
                    </ul>

                    <p><b>स्वास्थ्य सेवाएं:</b></p>
                    <ul>
                        <li>स्वास्थ्य शिविरों का आयोजन।</li>
                        <li>जरूरतमंदों के लिए मुफ्त चिकित्सा सेवाएं।</li>
                    </ul>

                    <p><b>विकलांगता सहायता:</b></p>
                    <ul>
                        <li>विकलांग व्यक्तियों के लिए सहायता और पुनर्वास कार्यक्रम।</li>
                    </ul>

                    <p><b>समाज सेवा:</b></p>
                    <ul>
                        <li>आपदा राहत कार्यों में भागीदारी।</li>
                        <li>जरूरतमंदों को राशन और अन्य आवश्यक सामग्री उपलब्ध कराना।</li>
                    </ul>

                    <p><b>महिला सशक्तिकरण:</b></p>
                    <ul>
                        <li>महिलाओं के अधिकारों के लिए जागरूकता अभियान।</li>
                        <li>महिला शिक्षा और व्यावसायिक प्रशिक्षण के अवसर प्रदान करना।</li>
                    </ul>





                </div>



            </div>



            <Footer />
        </>
    )
}

export default About
