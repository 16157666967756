import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import banner1 from './banner/banner1.jpg';
import banner2 from './banner/banner2.jpg';
import banner3 from './banner/banner3.jpg';
import banner4 from './banner/banner4.jpg';

const Carousal = () => {
  return (
    <div className="carousel-container">
      <Carousel
        fade
        interval={3000}
        controls={true}
        indicators={true}
      >
        <Carousel.Item>
          <img className="d-block w-100" src={banner1} alt="First slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={banner2} alt="Second slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={banner3} alt="Third slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={banner4} alt="Fourth slide" />
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default Carousal;
