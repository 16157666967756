import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './Components/Layout';
import Navbar from './Components/Navbar';
import Header from './Components/Header';

// index.js or App.js
import 'bootstrap/dist/css/bootstrap.min.css';
import Carousal from './Components/Carousal';
import Section from './Components/Section';
import Services from './Components/Services';
import ContactPage from './Components/ContactPage';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Gallery from './Pages/Gallery';
import Event from './Pages/Event';
import Videos from './Pages/Videos';
import PashudhanVikas from './Pages/PashudhanVikas';
import VectorControl from './Pages/VectorControl';
import TBControl from './Pages/TBControl';
import AnimalDiseaseControl from './Pages/AnimalDiseaseControl';
import Mahila from './Pages/Mahila';
import Kausal from './Pages/Kausal';
import News from './Pages/News';
  
function App() {
  return (
    <div className='main'>


    <BrowserRouter >
      <Header />
      <Navbar />
      <Routes>
        <Route path="*" element={<Layout />} />
        <Route path="/Carousal" element={<Carousal />} />
        <Route path="/Section" element={<Section />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/ContactPage" element={<ContactPage />} />
        <Route path="/About" element={<About />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Gallery" element={<Gallery />} />
        <Route path="/Event" element={<Event />} />
        <Route path="/Videos" element={<Videos />} />

        <Route path="/PashudhanVikas" element={<PashudhanVikas />} />
        <Route path="/VectorControl" element={<VectorControl />} />
        <Route path="/VectorControl" element={<VectorControl />} />
        <Route path="/TBControl" element={<TBControl />} />
        <Route path="/AnimalDiseaseControl" element={<AnimalDiseaseControl />} />
        <Route path="/Mahila" element={<Mahila />} />
        <Route path="/Kausal" element={<Kausal />} />
        <Route path="/News" element={<News />} />

      </Routes>
    </BrowserRouter>


  </div>
  );
}

export default App;
