import React from 'react';
import '../Pages/page.css'
import Footer from '../Components/Footer';

const TBControl = () => {
    return (
        <>

            <div className="tb-control">
                {/* Hero Section */}
                <section className="hero">
                    <h1>छय रोग (टीबी) नियंत्रण कार्यक्रम</h1>
                    <p>छय रोग (टीबी) के प्रसार को रोकने और प्रभावित लोगों को सहायता प्रदान करने हेतु समर्पित।</p>
                </section>

                {/* Purpose Section */}
                <section className="purpose">
                    <h2>कार्यक्रम का उद्देश्य</h2>
                    <p>
                        इस कार्यक्रम का उद्देश्य छय रोग को समाप्त करना, शीघ्र निदान और प्रभावी उपचार सुनिश्चित करना, और रोग के प्रसार को रोकना है।
                        हम विशेषकर कमज़ोर समुदायों पर ध्यान केंद्रित करते हैं जहाँ टीबी का खतरा अधिक है।
                    </p>
                </section>

                {/* Goals Section */}
                <section className="goals">
                    <h2>हमारे लक्ष्य</h2>
                    <ul>
                        <li>टीबी के प्रति जागरूकता फैलाना और इसके लक्षणों की पहचान करना।</li>
                        <li>रोग के शीघ्र निदान और उपचार के लिए सुविधाएं प्रदान करना।</li>
                        <li>टीबी के रोगियों के लिए समर्थन और परामर्श सेवाएं देना।</li>
                        <li>समुदायों में स्वास्थ्य सुविधाओं को सुदृढ़ करना।</li>
                    </ul>
                </section>

                {/* Services Section */}
                <section className="services">
                    <h2>हमारी सेवाएँ</h2>
                    <ul>
                        <li>टीबी का निःशुल्क परीक्षण और उपचार।</li>
                        <li>जागरूकता शिविर और स्वास्थ्य कार्यशालाएं।</li>
                        <li>मरीजों के लिए परामर्श और पुनर्वास सेवाएं।</li>
                        <li>टीबी रोकथाम हेतु मास्क और दवाइयों का वितरण।</li>
                    </ul>
                </section>

                {/* Awareness Section */}
                <section className="awareness">
                    <h2>जागरूकता कार्यक्रम</h2>
                    <p>
                        हमारा कार्यक्रम टीबी के लक्षणों, रोकथाम, और उपचार के बारे में जागरूकता फैलाने पर केंद्रित है।
                        हम ग्रामीण और शहरी क्षेत्रों में जागरूकता अभियान चलाते हैं ताकि समुदायों को रोग के खतरों के प्रति जागरूक किया जा सके।
                    </p>
                </section>

                {/* Contact Information */}
                <section className="contact">
                    <h2>संपर्क करें</h2>
                    <p>पता: 6 / 15 Vikas Nagar Lucknow UP India 226022</p>
                    <p>फोन: +91-9415495322</p>
                    <p>संस्था : जीवन पथ फाउंडेशन </p>
                </section>


            </div>


            <Footer />

        </>
    );
};

export default TBControl;
