import React from 'react'
import Footer from '../Components/Footer'

const News = () => {
  return (
    <>
    <div className='Heading'>
        Home / समाचार पत्र 
    </div>



    <Footer />

</>
  )
}

export default News
