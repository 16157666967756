import React, { useState } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import Footer from '../Components/Footer'

import skil1 from '../shakti/skill1.jpg'
import skil2 from '../shakti/skill2.jpg'
import skil3 from '../shakti/skill3.jpg'
import skil4 from '../shakti/skill4.jpg'
import skil5 from '../shakti/skill5.jpg'

const Kausal = () => {
    const [activePage, setActivePage] = useState(1);
    const totalPages = 4;

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };

    // Render pagination items
    let items = [];
    for (let number = 1; number <= totalPages; number++) {
        items.push(
            <Pagination.Item
                key={number}
                active={number === activePage}
                onClick={() => handlePageChange(number)}
            >
                {number}
            </Pagination.Item>
        );
    }

    return (
        <>
            <div className='Heading'>
                Home / कौशल  विकास
            </div>

            {activePage === 1 && (
                <div className='naari'>
                    <h1>Jeevan Path Foundation</h1>

                    <h2>उ.प्र. कौशल विकास मिशन-</h2>
                    <p>सम्पूर्ण भारत में उत्तर प्रदेश पहला राज्य है जिसने जुलाई 2013 में कौशल विकास नीति को प्रतिपादित किया तथा उसको लागू करने हेतु उत्तर प्रदेश कौशल विकास मिशन का शुभारंभ किया गया।</p>
                    <p>इस परियोजना के अंतर्गत भारत सरकार द्वारा वित्तपोषित पाँच योजनाओं (प्रधानमंत्री कौशल विकास योजना, एम० एस० डी० पी०, डी०डी०यू०जी०के०, आई. बी० ए० डी० पी० एवं एस० सी० ए० टू०. एम० सी० एम० पी०) तथा राज्य सरकार द्वारा वित्त पोषित BOCW सेस को समेकित करते हुए प्रदेश के 14 से 35 वर्ष की आयु के युवाओं को रोजगारपरक कौशल से प्रशिक्षित करने, प्रशिक्षण प्रदाताओं के माध्यम से उन्हें रोजगार दिलाने के लिए पूरे राज्य में एक साथ योजना प्रारम्भ की गई।</p>
                    <p>केन्द्र / राज्य सरकार से प्राप्त धनराशि के अतिरिक्त वित्त पोषण में अनुभव किये जाने वाले अन्तर की भरपाई तथा प्रशिक्षण हेतु आवश्यक धनराशि की व्यवस्था स्टेट स्किल डेवलपमेंट फंड (SSDF) के माध्यम से राज्य सरकार द्वारा की जाती है।</p>
                    <img src={skil4} />

                    <p>1. कौशल विकास नीति 2013 का प्रतिपादन एवं कियान्वयन।</p>
                    <p>2. कौशल विकास कार्यों को एकीकृत एवं मानकीकृत व्यवस्था के तहत संचालन हेतु कौशल विकास मिशन की स्थापना।</p>
                    <p>3. विभिन्न विभागों के कौशल विकास कार्यक्रमों का कॉमन नार्म्स के अनुसार संचालन।</p>
                    <p>4. विभिन्न प्रशिक्षण कार्यक्रमों में वित्तीय अन्तर की पूर्ति हेतु राज्य कौशल विकास निधि को SSDF के अन्तर्गत प्रावधान।</p>
                    <p>5. मिशन के प्रशिक्षणार्थियों का इत्र उद्योग के क्षेत्र में फ्रांस स्थित प्रतिष्ठित संस्थान में अंतर्राष्ट्रीय प्रशिक्षण।</p>

                </div>
            )}


            {activePage === 2 && (
                <div className='naari'>

                    <p>उद्यमशीलता विकसित करने हेतु PMKVY ट्रेनिंग के माध्यम से बड़े अभियान की ओर रुख किया गया है।</p>
                    <p>देश के युवा जो बीच में पढ़ाई छोड़ चुके हैं और अभी काम की तलाश कर रहे हैं। उन सभी युवाओं को प्रधानमंत्री कौशल विकास योजना के अंतर्गत निःशुल्क ट्रेनिंग देकर उन्हें रोजगार से जोड़ा जाता है। युवा अपनी इच्छा अनुसार कोर्स का चुनाव कर सकते हैं। PMKVY के अंतर्गत तकरीबन 40 से अधिक सेक्टर ऐसे हैं जिनमें युवा निःशुल्क ट्रेनिंग ले सकते हैं। युवाओं को ट्रेनिंग के दौरान पाठ्यक्रम जानकारी के साथ-साथ प्रैक्टिकल अनुभव दिया जाता है। जिससे युवा आसानी से ट्रेनिंग ले सकते हैं। पाठ्यक्रम पूर्ण होने के पश्चात युवाओं को एक असेसमेंट प्रक्रिया से गुजरना होता है। इसके उपरांत नेशनल स्किल डेवलपमेंट काउंसिल ऑफ इंडिया (NSDCI) द्वारा युवाओं को प्रमाणित किया जाता है।</p>
                    <p>PMKVY कोर्स से युवाओं को होने वाले फायदे:</p>
                    <img src={skil1} />
                    <ul>
                        <li>प्रधानमंत्री कौशल विकास योजना के अंतर्गत युवाओं को सभी सेक्टर्स में निःशुल्क ट्रेनिंग दी जाती है।</li>
                        <li>युवा अपनी इच्छा अनुसार ट्रेनिंग सेक्टर का चुनाव कर सकते हैं। जिसमें 40 से अधिक सेक्टर मौजूद हैं।</li>
                        <li>NSDC द्वारा तैयार किए गए National Skill Qualification Framework (NSQF) के अनुसार युवाओं को पाठ्यक्रम उपलब्ध करवाया जाता है।</li>
                        <li>युवा अपनी Skill को पाठ्यक्रम के अनुसार विकसित करते ही हैं, साथ ही उन्हें प्रेक्टिकल अनुभव भी दिया जाता है। जिससे उन्हें सीखने में आसानी होती है और वह आसानी से जॉब के दौरान उस कार्य को बड़ी दक्षता के साथ कर सकते हैं।</li>
                    </ul>
                    <p>जनहित में जारी</p>


                </div>
            )}





            {activePage === 3 && (
                <div className='naari'>
                    <p>ट्रेनिंग पूर्ण होने के पश्चात युवाओं को NSQF लेवल के अनुसार असेसमेंट पास करना होता है।</p>
                    <p>असेसमेंट में पास हुए युवाओं को NSDC द्वारा सर्टिफिकेट उपलब्ध करवाया जाता है।</p>
                    <p>युवाओं को ट्रेनिंग के दौरान आर्थिक सहायता भी दी जाती है, जो ₹500 से लेकर अधिकतम 2100 या उससे अधिक हो सकती है।</p>
                    <img src={skil2} />
                    <ul>
                        <li>PMKVY training course में सम्मिलित होने के लिए युवाओं को नजदीकी PMKVY center पर आवेदन करना होता है।</li>
                        <li>युवा चाहे तो Skill India की ऑफिशल वेबसाइट पर आवेदन कर सकते हैं। आपके नजदीक में PMKVY center द्वारा आपको कॉल की जाती है।</li>
                    </ul>
                    <p>ट्रेनिंग पूर्ण होने के पश्चात युवाओं को जॉब प्लेसमेंट दी जाती है और युवाओं की संपूर्ण जॉब प्लेसमेंट ट्रैकिंग का रिकॉर्ड मेंटेन किया जाता है।</p>

                    <h3>PMKVY Training Fee Details | PMKVY ट्रेनिंग फीस विवरण</h3>
                    <p>जैसा कि आपको उपरोक्त पंक्तियों में बताया जा चुका है कि PM Kaushal Vikas Yojana के अंतर्गत युवाओं को आर्थिक लाभ दिया जाता है। अतः skill training ले रहे युवाओं से किसी प्रकार की आवेदन राशि नहीं ली जाती है। उन्हें निःशुल्क ट्रेनिंग उपलब्ध करवाई जाती है। जैसे ही युवा assessment पास करते हैं, उन्हें बैंक खाते में DBT द्वारा आर्थिक लाभ भी दिया जाता है। PMKVY के अंतर्गत जो भी युवा ट्रेनिंग लेते हैं, उन्हें किसी प्रकार की ट्रेनिंग फीस, आवेदन शुल्क का भुगतान नहीं करना होता है। उल्टे उन्हें सरकार द्वारा आर्थिक लाभ दिया जाता है और जॉब प्लेसमेंट दी जाती है।</p>
                    <p>जनहित में जारी</p>

                </div>
            )}


            {activePage === 4 && (
                <div className='naari'>

                    <h2>यूपी कृषि उपकरण सब्सिडी योजना 2023</h2>
                    <p>उत्तर प्रदेश कृषि उपकरण योजना पूरी तरह से किसानों के हितों में शुरू की गयी योजना है, इसके माध्यम से किसानों को खेती हेतु किसी भी उपकरण की खरीद हेतु सब्सिडी दी जाएगी। जिसका सीधा सीधा लाभ उनके कृषि फसल उत्पादन पर पड़ेगा। पहले किसान खेती के सभी उपकरण खरीद नहीं पाता था, लेकिन अब ऐसा नहीं होने वाला है। किसानों को नाममात्र के मूल्य पर (लगभग 50% तक अनुदान) खेती के उपकरण प्राप्त हो जायेंगे। किसानों के जीवन स्तर व आर्थिक स्थिति में भी काफी सुधार आएगा। सरकार की इस योजना से किसानों को प्रोत्साहन मिलेगा। उपकरण सब्सिडी योजना के माध्यम से दूरस्थ सीमांत व पिछड़े वर्ग के लोगों को इसका लाभ मिल पायेगा।</p>
                    <img src={skil3} />
                    <h3>उत्तर प्रदेश कृषि उपकरण योजना के उद्देश्य</h3>
                    <p>यूपी कृषि उपकरण इनपुट योजना का मुख्य उद्देश्य किसानों को सब्सिडी पर कृषि यंत्र उपलब्ध करवाना है। इसके अलावा, इस योजना को यदि पूरी प्लानिंग के साथ जमीनी स्तर पर उतारा जाए तो इससे कई उद्देश्यों की पूर्ति हो सकती है। इससे किसानों के जीवन स्तर में सुधार आ सकता है, फसल की गुणवत्ता बढ़ सकती है, और किसानों को कम शारीरिक श्रम करना पड़ेगा। साथ ही, सरकार का किसानों की आय दुगुना करने का सपना भी पूरा हो सकता है। किसानों को सब्सिडी पर उपकरण खरीद के लिए टोकन को जेनेरेट करना होगा।</p>
                    <p>जनहित में जारी</p>

                    <h2>उत्तर प्रदेश गन्ना भुगतान 2023</h2>
                    <img src={skil5} />
                    <p>गन्ना उत्पादन में उत्तर प्रदेश अन्य राज्यों से आगे है और चीनी के उत्पादन के लिए गन्ने की खेती बहुत जरुरी है। परंतु उत्तर प्रदेश के किसान यूपी गन्ना भुगतान (UP Ganna Payment 2023) समय पर न हो पाने की समस्या से परेशान रहते हैं। किसानों की इसी समस्या को देखते हुए यूपी की राज्य सरकार ने...</p>



                </div>
            )}

            <div className="d-flex justify-content-center my-4 pagination-container">
                <Pagination size="lg">{items}</Pagination>
            </div>

            <Footer />

        </>
    )
}

export default Kausal
