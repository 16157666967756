import React, { useState } from 'react';

const ContactPage = () => {
  // Define state to store form input values
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [contact, setContact] = useState('');
  const [address, setAddress] = useState('');

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Format the message to send via WhatsApp
    const message = `Hello, I would like to contact you.\n\nName: ${name}\nEmail: ${email}\nContact: ${contact}\nAddress: ${address}`;

    // WhatsApp number to send message to
    const whatsappNumber = '+919415495322';

    // Encode the message for URL
    const encodedMessage = encodeURIComponent(message);

    // Redirect the user to WhatsApp with the formatted message
    window.open(`https://wa.me/${whatsappNumber}?text=${encodedMessage}`, '_blank');
  };

  return (
    <div className="contact-page">
      <div className="google-map">
        <iframe
          title="Google Map"
          src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3558.724887541626!2d80.9508493754384!3d26.880480476666463!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjbCsDUyJzQ5LjciTiA4MMKwNTcnMTIuMyJF!5e0!3m2!1sen!2sin!4v1730908626419!5m2!1sen!2sin"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>

      <div className="contact-form">
        <h2>Contact Us | संपर्क करें</h2>
        <form onSubmit={handleSubmit}>
          <label htmlFor="name">Name | नाम</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Enter your name | अपना नाम दर्ज करें"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />

          <label htmlFor="email">Email | ईमेल</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Enter your email | अपना ईमेल दर्ज करें"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <label htmlFor="contact">Contact | संपर्क नंबर</label>
          <input
            type="tel"
            id="contact"
            name="contact"
            placeholder="Enter your contact number | अपना संपर्क नंबर दर्ज करें"
            value={contact}
            onChange={(e) => setContact(e.target.value)}
            required
          />

          <label htmlFor="address">Address | पता</label>
          <textarea
            id="address"
            name="address"
            placeholder="Enter your address | अपना पता दर्ज करें"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
          ></textarea>

          <button type="submit">Submit | सबमिट करें</button>
        </form>
      </div>
    </div>
  );
};

export default ContactPage;
