import React from 'react'
import './section.css';
import about from './Gallery/gal  (6).jpeg'
import { NavLink } from 'react-router-dom';

const Section = () => {
    return (
        <div className='sections'>

            <div className='section2'>
                <img src={about} />


                <a href="Tel:+91-9415495322">
                    <button class="contact-button">Contact Us | हमारा संपर्क</button>
                </a>
            </div>


            <div className='section2'>
                <h1>जीवन पथ फाउंडेशन मे आपका  स्वागत  है</h1>
                <p>"जीवन पथ फाउंडेशन" एक गैर-लाभकारी संगठन   है, जिसका उद्देश्य समाज में सकारात्मक बदलाव लाना है। यह संगठन विभिन्न समाजिक कार्यों, शिक्षा, स्वास्थ्य, और मानवाधिकार के क्षेत्र में कार्यरत है। जीवन पथ फाउंडेशन का प्रमुख उद्देश्य उन लोगों की मदद करना है जो समाज के हाशिये पर हैं, जैसे गरीब, असहाय, और विकलांग व्यक्ति। इसके अतिरिक्त, यह संगठन विभिन्न शैक्षिक कार्यक्रम, प्रशिक्षण, और जागरूकता अभियानों के माध्यम से लोगों को उनके अधिकारों के बारे में जानकारी देता है।</p>


                <p> उत्तर प्रदेश के  सभी  75 जनपदों  में   ग्रामीण, खण्ड, और जनपद    स्तरीय कार्यक्रम का क्रियान्वयन   18 मण्डल 834 खण्ड 1,19,000 गांवों में <span style={{ color: '#ff9933', fontWeight: 'bold' }}>जीवन पथ फाउंडेशन के माध्यम से</span>  संचालित किया जा रहा है. </p>



                <p>भारत सरकार ने 1962 से राष्ट्रीय क्षय नियंत्रण कार्यक्रम लागू किया। इसके अंतर्गत जिला स्तर पर एक निगरानी और पर्यवेक्षण इकाई के रूप में जिला क्षय निवारण केंद्र की स्थापना की गई। हमारे प्रदेश में 1966 से उक्त कार्यक्रम की क्रियान्विति की गई।"  जिनमें केंद्र और राज्य सरकारें मिलकर टीबी रोग के  प्रचार प्रसार को रोकने और इसे पूरी तरह समाप्त करने का लक्ष्य रखती हैं। </p>

                <NavLink
                    to="/About"
                    style={{
                        textDecoration: 'none',
                        color: '#ff9933',
                        fontSize: '12px',
                        fontWeight: 'bold',
                        padding: '10px 20px',
                        backgroundColor: '#fff',
                        borderRadius: '30px',
                        border: '2px solid #ff9933',
                        display: 'inline-block',
                        transition: 'all 0.3s ease',
                    }}
                    activeStyle={{
                        color: '#fff',
                        backgroundColor: '#ff9933',
                        borderColor: '#ff9933',
                    }}
                >
                    Read More
                </NavLink>





            </div>



        </div>
    )
}

export default Section
