import React from 'react'
import Carousal from '../Components/Carousal'
import Section from './Section'
import Services from './Services'
import ContactPage from './ContactPage'
import Footer from './Footer'
import Videos from '../Pages/Videos'
import Slider from '../Pages/Slider'

const Layout = () => {
  return (
    <div>
      <Carousal/>
      <Section/>
      <Services/>
      <Videos/>
      <hr/>
      <Slider/>
      <ContactPage/>
      <Footer/>
    </div>
  )
}

export default Layout
