import React from 'react';
import '../Pages/page.css';
import Footer from '../Components/Footer';

const AnimalDiseaseControl = () => {
  return (
    <>
      <div className="animal-disease-control">
        {/* Hero Section */}
        <section className="hero">
          <h1>राष्ट्रीय पशु रोग नियंत्रण कार्यक्रम</h1>
          <p>पशुओं में रोगों की रोकथाम और नियंत्रण के लिए समर्पित राष्ट्रीय कार्यक्रम।</p>
        </section>

        {/* Purpose Section */}
        <section className="purpose">
          <h2>कार्यक्रम का उद्देश्य</h2>
          <p>
            राष्ट्रीय पशु रोग नियंत्रण कार्यक्रम का उद्देश्य प्रमुख पशु रोगों जैसे खुरपका-मुंहपका (FMD) 
            और ब्रुसेलोसिस को नियंत्रित करना और पशु स्वास्थ्य को बनाए रखना है।
          </p>
        </section>

        {/* Goals Section */}
        <section className="goals">
          <h2>हमारे लक्ष्य</h2>
          <ul>
            <li>खुरपका-मुंहपका और ब्रुसेलोसिस के प्रसार को नियंत्रित करना।</li>
            <li>समय पर टीकाकरण और जांच कार्यक्रम संचालित करना।</li>
            <li>पशुपालकों को पशु रोगों के प्रति जागरूक बनाना।</li>
            <li>पशुधन का उत्पादन और उत्पादकता बढ़ाना।</li>
            <li>स्थानीय समुदायों को पशुओं के स्वास्थ्य और पोषण के बारे में शिक्षित करना।</li>
          </ul>
        </section>

        {/* Services Section */}
        <section className="services">
          <h2>हमारी सेवाएँ</h2>
          <ul>
            <li>खुरपका-मुंहपका और ब्रुसेलोसिस रोगों का टीकाकरण।</li>
            <li>पशुओं का नियमित स्वास्थ्य परीक्षण।</li>
            <li>पशुपालकों को चिकित्सा परामर्श और सहायता।</li>
            <li>पशुओं के लिए स्वच्छता और स्वास्थ्य की जानकारी देना।</li>
          </ul>
        </section>

        {/* Awareness Section */}
        <section className="awareness">
          <h2>जागरूकता कार्यक्रम</h2>
          <p>
            हमारा कार्यक्रम पशुपालकों को पशु रोगों की रोकथाम और उनके उपचार के बारे में 
            जागरूक करने पर केंद्रित है। हम गाँवों, कस्बों और पशु मेलों में जागरूकता अभियान चलाते हैं।
          </p>
        </section>

        {/* Contact Section */}
        <section className="contact">
          <h2>संपर्क करें</h2>
          <p>पता: 6 / 15 विकास नगर, लखनऊ, उत्तर प्रदेश, भारत 226022</p>
          <p>फोन: +91-9415495322</p>
          <p>संस्था : जीवन पथ फाउंडेशन</p>
        </section>
      </div>

      <Footer />
    </>
  );
};

export default AnimalDiseaseControl;
