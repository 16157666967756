import React from "react";
import '../service.css';
import pashu from './img/pashu.jpg';
import vector from './img/vector.jpg';
import tv from './img/tv.jpg';
import pasurog from './img/pashurog.jpg';
import dairy from './img/dairy.jpg';
import valvikas from './img/balviks.jpg';

const services = [
  {
    name: "पशुधन विकास परिषद",
    description:
      "नाबार्ड डेयरी फार्मिंग योजना के तहत आप डेयरी फार्म शुरू करने के लिए 10 लाख रुपये का ऋण प्राप्त कर सकते हैं। यह योजना किसानों को अपनी आय बढ़ाने और ग्रामीण अर्थव्यवस्था को सशक्त बनाने का अवसर प्रदान करती है, जिससे पशुपालन और डेयरी उद्योग में एक स्थिर और उन्नत भविष्य की दिशा मिलती है।",
    imgSrc: pashu, // Use the imported image here
  },
  {
    name: "वेक्टर जनित नियंत्रण कार्यक्रम",
    description:
      "राष्ट्रीय वेक्टर जनित रोग नियंत्रण कार्यक्रम भारत में वेक्टर जनित रोगों की रोकथाम के लिए चलाया जाता है। इस कार्यक्रम का उद्देश्य मच्छरों और अन्य कीटों के प्रसार को नियंत्रित करना है, जिससे मलेरिया, डेंगू, चिकनगुनिया और अन्य रोगों का खतरा कम किया जा सके। यह कार्यक्रम स्वास्थ्य और पर्यावरण के संरक्षण के लिए एक महत्वपूर्ण कदम है।",
    imgSrc: vector, // Use the imported image here
  },
  {
    name: "छय रोग नियंत्रण कार्यक्रम",
    description:
      "क्षय रोग (टीबी) नियंत्रण कार्यक्रम, टीबी के संक्रमण और प्रसार को रोकने के लिए चलाया जाने वाला कार्यक्रम है। यह योजना राष्ट्रीय स्तर पर टीबी के उपचार, जागरूकता, और रोकथाम के उपायों को बढ़ावा देती है। टीबी को समाप्त करने के उद्देश्य से यह पहल भारत में एक स्वस्थ समाज के निर्माण में योगदान देती है।",
    imgSrc: tv, // Use the imported image here
  },
  {
    name: "राष्ट्रीय पशु रोग नियंत्रण कार्यक्रम",
    description:
      "इस कार्यक्रम का मकसद पशुओं की सेहत सुधारकर किसानों को फ़ायदा पहुंचाना है। राष्ट्रीय पशु रोग नियंत्रण कार्यक्रम किसानों को उनकी पशुधन की देखभाल में मदद करता है, जिससे उत्पादन में वृद्धि होती है और पशुओं से होने वाली बीमारियाँ नियंत्रित की जाती हैं। यह कार्यक्रम किसानों के लिए एक सुरक्षित और लाभकारी भविष्य सुनिश्चित करता है।",
    imgSrc: pasurog, // Use the imported image here
  },
  {
    name: "डेयरी संचालन",
    description:
      "दुग्धशाला या डेरी में पशुओं का दूध निकालने तथा संबंधित अन्य गतिविधियाँ की जाती हैं। यह उद्योग ग्रामीण क्षेत्र के विकास में अहम भूमिका निभाता है, जहाँ न केवल किसानों को आय होती है, बल्कि डेयरी उद्योग में रोजगार के भी अवसर सृजित होते हैं। इसके अलावा, यह योजना दूध की गुणवत्ता में सुधार और पशुओं की भलाई पर भी ध्यान केंद्रित करती है।",
    imgSrc: dairy, // Use the imported image here
  },
  {
    name: "बाल विकास योजना",
    description:
      "स्वस्थ और समृद्ध समाज के निर्माण हेतु बाल स्वास्थ्य, शिक्षा और पोषण पर बल दिया जाता है। यह योजना बच्चों के शारीरिक, मानसिक और शैक्षिक विकास के लिए विशेष रूप से तैयार की गई है। बालकों के लिए अच्छे आहार, शिक्षा और स्वास्थ्य सेवाएँ सुनिश्चित करने से न केवल उनका व्यक्तिगत विकास होता है, बल्कि समाज का समग्र विकास भी संभव होता है। यह नीति बच्चों के बेहतर भविष्य की दिशा में एक महत्वपूर्ण कदम है।",
    imgSrc: valvikas, // Use the imported image here
  },
];

const Services = () => {
  return (
    <div className="services-container">
      <h1 className="services-title">हमारी सेवाएँ</h1>
      <div className="services-grid">
        {services.map((service, index) => (
          <div key={index} className="service-card">
            <img
              src={service.imgSrc}
              alt={service.name}
              className="service-image"
            />
            <h2 className="service-name">{service.name}</h2>
            <p className="service-description">{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
