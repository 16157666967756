import React from 'react';
import '../Pages/page.css'
import Footer from '../Components/Footer';

const VectorControl = () => {
  return (
   <>
   
   <div className="vector-control">
      {/* Hero Section */}
      <section className="hero">
        <h1>वेक्टर जनित नियंत्रण कार्यक्रम</h1>
        <p>भारत में वेक्टर जनित रोगों को रोकने और नियंत्रण के लिए समर्पित।</p>
      </section>

      {/* Purpose Section */}
      <section className="purpose">
        <h2>कार्यक्रम का उद्देश्य</h2>
        <p>
          वेक्टर जनित रोग नियंत्रण कार्यक्रम का उद्देश्य मलेरिया, डेंगू, चिकनगुनिया,
          जापानी एन्सेफलाइटिस, फाइलेरिया जैसे वेक्टर जनित रोगों को रोकना और 
          उनके प्रसार को नियंत्रित करना है।
        </p>
      </section>

      {/* Goals Section */}
      <section className="goals">
        <h2>हमारे लक्ष्य</h2>
        <ul>
          <li>रोगों के बारे में जागरूकता फैलाना और रोकथाम के उपायों का प्रचार करना।</li>
          <li>सक्रिय जांच और उपचार कार्यक्रम आयोजित करना।</li>
          <li>सफाई अभियान और मच्छररोधी उपायों को बढ़ावा देना।</li>
          <li>सक्रिय नियंत्रण और फॉगिंग अभियान द्वारा मच्छरों का नियंत्रण।</li>
          <li>स्थानीय समुदायों को स्वस्थ रहने के तरीके सिखाना।</li>
        </ul>
      </section>

      {/* Services Section */}
      <section className="services">
        <h2>हमारी सेवाएँ</h2>
        <ul>
          <li>मलेरिया और डेंगू के परीक्षण और उपचार।</li>
          <li>मच्छर नियंत्रण अभियान जैसे फॉगिंग, स्प्रे, और पानी के ठहराव की सफाई।</li>
          <li>स्वास्थ्य शिविर और चिकित्सा परामर्श सेवाएँ।</li>
          <li>स्वच्छता और सफाई को बढ़ावा देना।</li>
        </ul>
      </section>

      {/* Awareness Section */}
      <section className="awareness">
        <h2>जागरूकता कार्यक्रम</h2>
        <p>
          हमारा कार्यक्रम लोगों को सुरक्षित रहने और वेक्टर जनित रोगों से बचने के तरीके 
          सिखाने पर ध्यान केंद्रित करता है। हम स्कूलों, समुदायों, और सार्वजनिक स्थानों 
          पर जागरूकता अभियान चलाते हैं।
        </p>
      </section>

      
      <section className="contact">
        <h2>संपर्क करें</h2>
        <p>पता: 6 / 15 Vikas Nagar Lucknow UP India 226022</p>
        <p>फोन: +91-9415495322</p>
        <p>संस्था : जीवन पथ फाउंडेशन </p>
      </section>


    </div>


    <Footer/>
   
   </>
  );
};

export default VectorControl;
