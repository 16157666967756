import React from 'react';

import vid1 from '../Components/Videos/vid (4).mp4';
import vid2 from '../Components/Videos/vid (2).mp4';
import vid3 from '../Components/Videos/vid (3).mp4';

import vid4 from '../Components/Videos/newvid (1).mp4';
import vid5 from '../Components/Videos/newvid (2).mp4';
import vid6 from '../Components/Videos/newvid (3).mp4';

const Videos = () => {
    return (
        <div className="video-container">
            <video src={vid1} className="video-item"  controls loop muted={false} />
            <video src={vid2} className="video-item"  controls loop muted={false} />
            <video src={vid3} className="video-item"  controls loop muted={false} />
            <video src={vid4} className="video-item"  controls loop muted={false} />
            <video src={vid5} className="video-item"  controls loop muted={false} />
            <video src={vid6} className="video-item"  controls loop muted={false} />
        </div>
    );
};

export default Videos;




