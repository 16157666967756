import React from 'react'

import slide1 from '../Components/img/tubecul.jpg'
import slide2 from '../Components/img/pashu.jpg'
import slide3 from '../Components/img/rogpashu.jpg'
import slide4 from '../Components/img/janitvectort.jpg'

const Slider = () => {
    return (
        <>


            <div className='title'>
              OUR BLOG
            </div>

            <div className='blogs'>

                <div className='blog2'>
                    <img src={slide1} />
                </div>

                <div className='blog2'>
                    <img src={slide2} />
                </div>

                <div className='blog2'>
                    <img src={slide3} />
                </div>

                <div className='blog2'>
                    <img src={slide4} />
                </div>

            </div>

        </>
    )
}

export default Slider
