import React from 'react';
import Footer from '../Components/Footer';


const galleryImages = [];
for (let i = 1; i <= 71; i++) {
  galleryImages.push(require(`../Components/Gallery/gal  (${i}).jpeg`));
}


const updateImages = [];
for (let i = 1; i <= 38; i++) {
  updateImages.push(require(`../Components/Update/newgal (${i}).jpeg`));
}


const images = [...galleryImages, ...updateImages];

const Gallery = () => {
  return (
    <>
      <div className='Heading'>
        Home / Gallery
      </div>

      <div className="gallery-container">
        <div className="gallery-grid">
        
          {images.map((image, index) => (
            <div key={index} className="gallery-item">
              <img src={image} alt={`Gallery image ${index + 1}`} />
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Gallery;
