import React from 'react';
import '../Pages/page.css'
import Footer from '../Components/Footer';

const PashudhanVikas = () => {
    return (


        <>




            <div className="pashudhan-vikas">
                {/* Hero Section */}
                <section className="hero">
                    <h1>पशुधन विकास परिषद </h1>
                    <p>भारत में पशुओं के कल्याण और विकास के प्रति समर्पित।</p>
                </section>

                {/* Mission Section */}
                <section className="mission">
                    <h2>हमारा मिशन</h2>
                    <p>
                        पशुधन विकास परिषद का उद्देश्य पशुओं के जीवन की गुणवत्ता को सुधारना है,
                        जिसमें कल्याण कार्यक्रम, शैक्षिक पहल, और समुदाय की सहभागिता शामिल है।
                    </p>
                </section>

                {/* Services Section */}
                <section className="services">
                    <h2>हमारी सेवाएँ</h2>
                    <ul>
                        <li>पशु चिकित्सा सेवाएँ</li>
                        <li>पशु आश्रय गृह</li>
                        <li>टीकाकरण अभियान</li>
                        <li>पशुधन प्रशिक्षण</li>
                        <li>पशु संरक्षण एवं पुनर्वास</li>
                    </ul>
                </section>

                {/* Programs Section */}
                <section className="programs">
                    <h2>हमारे कार्यक्रम</h2>
                    <p>
                        ग्रामीण क्षेत्रों में पशुपालन प्रशिक्षण, पशु स्वास्थ्य शिविर, और पशु अधिकारों
                        के प्रति जागरूकता फैलाने के कार्यक्रम।
                    </p>
                </section>

                {/* Gallery Section */}
                <section className="gallery">
                    <h2>चित्र प्रदर्शनी</h2>
                    <p>हमारे कार्यक्रमों और पशुधन के कार्यों की कुछ झलकियाँ।</p>
                    {/* Add images here as needed */}
                </section>

                <section className="contact">
                    <h2>संपर्क करें</h2>
                    <p>पता: 6 / 15 Vikas Nagar Lucknow UP India 226022</p>
                    <p>फोन: +91-9415495322</p>
                    <p>संस्था : जीवन पथ फाउंडेशन </p>
                </section>


            </div>





            <Footer />

        </>
    );
};

export default PashudhanVikas;
