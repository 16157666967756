import React, { useState } from 'react';
import Footer from '../Components/Footer';

const Contact = () => {
  // State for managing form inputs
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Handle form submission (redirect to WhatsApp with form data)
  const handleSubmit = (e) => {
    e.preventDefault();

    // Format the message to send via WhatsApp
    const whatsappMessage = `Hello, I would like to contact you.\n\nName: ${formData.name}\nEmail: ${formData.email}\nMessage: ${formData.message}`;

    // WhatsApp number to send the message to
    const whatsappNumber = '+919415495322';  // Replace with your own WhatsApp number

    // Encode the message for URL
    const encodedMessage = encodeURIComponent(whatsappMessage);

    // Redirect to WhatsApp with the message
    window.open(`https://wa.me/${whatsappNumber}?text=${encodedMessage}`, '_blank');
  };

  return (
    <>
      <div className='Heading'>
        Home / Contact
      </div>

      <div className="contact-container">
        <h1 className="contact-title">Contact Us / संपर्क करें</h1>

        {/* Contact Form */}
        <form onSubmit={handleSubmit} className="contact-form">
          <div className="form-group">
            <label htmlFor="name">Name / नाम:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
              placeholder="Enter your name"
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email / ईमेल:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
              placeholder="Enter your email"
            />
          </div>

          <div className="form-group">
            <label htmlFor="message">Message / संदेश:</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              required
              placeholder="Enter your message"
            />
          </div>

          <button type="submit" className="submit-button">Submit / भेजें</button>
        </form>

        {/* Google Map */}
        <div className="google-map">
          <iframe
            title="Google Map"
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3558.724887541626!2d80.9508493754384!3d26.880480476666463!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjbCsDUyJzQ5LjciTiA4MMKwNTcnMTIuMyJF!5e0!3m2!1sen!2sin!4v1730908626419!5m2!1sen!2sin"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Contact;
